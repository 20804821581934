import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const Layout = () => {
    const { t } = useTranslation();
	return (
		<>
            <Helmet htmlAttributes={{ lang : i18n.language }}>
                <meta charSet="utf-8" />
                <title>{t("meta.title")}</title>
				<meta name="description" content={t("meta.description")} />
            </Helmet>		
            <div className="Wrapper">

                <Outlet />

            </div>
		</>
	);
};

export default Layout;

import Logo from "../components/Logo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const About = () => {
	const { t } = useTranslation();
	
	return (
		<>
			<div className="Header">
				<Logo />
				<Nav />
			</div>
			<div className="Main">
				<section className="SimplePage">
				<h1>{t("pages:about.title")}</h1>
					<div
						dangerouslySetInnerHTML={{
							__html: t("pages:about.text", {
								interpolation: { escapeValue: false },
							}),
						}}
					/>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default About;

import Logo from "../components/Logo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const Datenschutz = () => {
	return (
		<>
			<div className="Header">
				<Logo />
				<Nav />
			</div>
			<div className="Main">
				<section className="SimplePage">
					<h1>Datenschutz</h1>
					<div id="cookiefirst-policy-page"></div>
  					<div>Diese Cookie-Richtlinie wurde erstellt und aktualisiert von der Firma <a href="https://cookiefirst.com/de/consent-management-platform/">CookieFirst - Consent Management Platform</a>.</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default Datenschutz;

import {useEffect, useRef, useState} from 'react';
import {onAuthStateChanged} from "firebase/auth";
import {
    addEmployer,
    auth,
    listenToEmployers,
    signInWithGooglePopup,
    adminSignOut,
    checkPermissions
} from "../services/firebase";

import Logo from "../components/Logo";
import Nav from "../components/Nav";

function AdminApp() {
    const [user, setUser] = useState();
    const [userIsVerified, setUserIsVerified] = useState();
    const [employers, setEmployers] = useState({});

    const employerNameInput = useRef();
    const inviteCodeInput = useRef();

    const employersListener = useRef();

    const logGoogleUser = async () => {
        const response = await signInWithGooglePopup();
        console.log(response)
    };

    useEffect(() => {
        onAuthStateChanged(auth, (userUpdate) => {
            if (userUpdate && userUpdate !== user) {
                setUser(userUpdate);
                checkPermissions(userUpdate.uid).then(result => {
                    setUserIsVerified(result);
                });
                employersListener.current = listenToEmployers(setEmployers)
            } else {
                if (employersListener.current ) {
                    employersListener.current.off();
                }
                setEmployers({});
                setUser(null);
            }
        });

        return employersListener.current && employersListener.current.off()
    }, []);

    function addNewEmployer(e) {
        e.preventDefault();
        const employerName = employerNameInput.current.value;
        const inviteCode = inviteCodeInput.current.value;

        if (employers) {
            if (Object.keys(employers).includes(employerName)) {
                alert('Company name already exists!');
                return;
            }

            if (Object.values(employers).includes(inviteCode)) {
                alert('Invite code already used by another company!');
                return;
            }
        }
        addEmployer(employerName, inviteCode);
    }

    return (
        <>
            <div className="Header">
                <Logo/>
                <Nav/>
            </div>
            <div className="Main">
                <div className="AdminPage">
                    {!user && <button onClick={logGoogleUser}>Sign In With Google</button>}
                    {user && <div>
                        <h1>
                            Admin
                            <button className='btn outline' onClick={adminSignOut}>Sign Out</button>
                        </h1>
                        
                        {!userIsVerified && <div>You haven't yet been verified. Please speak to your super admin.</div>}
                        {userIsVerified && <div>
                            <form className='AdminPage--form' onSubmit={addNewEmployer}>
                                <legend>Create Employer</legend>
                                <fieldset>
                                    <label>Employer Name: </label>
                                    <input ref={employerNameInput}/>
                                </fieldset>
                                <fieldset>
                                    <label>Unique Invite Code: </label>
                                    <input minlength="5" ref={inviteCodeInput} placeholder="Eg. 'kal-j2l-9p3' or 'Julius-Baer-2023'"/>
                                </fieldset>
                                <fieldset>
                                    <button onClick={addNewEmployer}>Add</button>
                                </fieldset>
                            </form>
                            <div className='AdminPage--result'>
                                <h2>Existing Codes</h2>
                                <table>
                                    <thead>
                                        <th>Name</th>
                                        <th>Password</th>
                                    </thead>
                                    <tbody>
                                        {employers && Object.entries(employers).map(([name, {dirtyInviteCode}]) => (
                                            <tr>
                                                <td>{name}</td>
                                                <td>{dirtyInviteCode}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                    </div>}
                </div>
            </div>
        </>
    );
}

export default AdminApp;

import logoSrc from '../img/logo.svg';

const Logo = () => {
	return (
		<>
			<a className='HeaderLogo' href="/">
				<img src={logoSrc} />
			</a>
		</>
	);
};

export default Logo;

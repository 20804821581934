import Logo from "../components/Logo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Companies = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="Header">
				<Logo />
				<Nav />
			</div>
			<div className="Main">
				<section className="SimplePage">
					<h1>{t("pages:companies.intro.title")}</h1>
					<div
						dangerouslySetInnerHTML={{
							__html: t("pages:companies.intro.text", {
								interpolation: { escapeValue: false },
							}),
						}}
					/>
					<hr />
					<h2>{t("pages:companies.block2.title")}</h2>
					<ol className="large">
						{t("pages:companies.block2.items", { returnObjects: true }).map(
							({ title, text }) => (
								<li>
									<h3>{title}</h3>
									<div
										dangerouslySetInnerHTML={{
											__html: text,
										}}
									/>
								</li>
							)
						)}
					</ol>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default Companies;

import {useRef, useState} from "react";
import {verifyInviteCode} from '../services/firebase';

import Logo from "../components/Logo";
import Nav from "../components/Nav";
import sanitizeInviteCode from "../services/sanitizeInviteCode";
import { useTranslation } from "react-i18next";

const Start = () => {
	const inviteCodeInput = useRef();
	const [formError, setFormError] = useState(false);

	const { t } = useTranslation();

	const onInviteSubmit = (e) => {
		e.preventDefault();
		const inviteCode = sanitizeInviteCode(inviteCodeInput.current.value);

		if (inviteCode.length > 3) {
			verifyInviteCode(inviteCode).then(result => {
				if (result) {
					setFormError(false);
					window.sessionStorage.setItem('invite code', inviteCode);
					window.sessionStorage.setItem('employer', result.employer);
					window.location = 'survey';
				} else {
					setFormError("Invalid invitation code. Please check for any typos.")
				}
			}).catch(e => {
				setFormError("Invalid invitation code. Please check for any typos.")
			})
		} else {
			setFormError('Please enter a complete invitation code')
		}
	};

	return (
		<>
			<div className="Header">
				<Logo />
				<Nav />
			</div>
			<div className="Main">
				<div className="InviteForm">
					<strong>{t("dictionary.enterInviteCode")}</strong>
					<form className="InviteForm--Form" onSubmit={onInviteSubmit}>
						
						<input ref={inviteCodeInput}/>
						<button type="submit">{t("dictionary.start")}</button>
					</form>
					{formError && <p className='error'>{formError}</p>}
				</div>
			</div>
		</>
	);
};

export default Start;

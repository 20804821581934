import React, {useState, useEffect, useRef} from "react";
import ReactMarkdown from 'react-markdown'
import Icon from "./components/Icon";
import Footer from "./components/Footer";
import logoEnableMe from './img/logo-enableme.svg';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {useTranslation} from "react-i18next";
import i18n from "./i18n";

gsap.registerPlugin(ScrollTrigger);

function isDebugMode() {
    return window.location.href.includes('debug');
}

const icons = {
    "ich-selbst": 'person',
    "mein-team": 'users-four',
    "meine-fhrungskraft": 'briefcase',
    "meine-organisation": 'buildings',
};

const externalLinks = [
    {
      url: "https://www.enableme.de/de/themen/inklusion-am-arbeitsplatz-eine-bereicherung-fur-unternehmen-1209",
      title: "Inklusion am Arbeitsplatz I EnableMe",
      image: "https://www.enableme.de/countrys/germany/topics/employer/woman-face-coloured.jpg"
    },
    {
      url: "https://www.enableme.de/de/artikel/psychische-erkrankungen-am-arbeitsplatz-9571",
      title: "Psychische Erkrankungen am Arbeitsplatz | EnableMe",
      image: "https://www.enableme.de/global/topics/job/conversation-business.jpg"
    },
    {
      url: "https://www.enableme.de/de/artikel/fur-mitarbeiter-innen-sieben-tipps-zur-inklusion-am-arbeitsplatz-2047",
      title: "Sieben Tipps zur Inklusion am Arbeitsplatz",
      image: "https://www.enableme.de/countrys/germany/topics/education/people-having-a-meeting.jpg"
    },
    // {
    //   url: "https://www.enableme.de/de/artikel/wiedereinstieg-nach-burnout-9573",
    //   title: "Wiedereinstieg nach Burnout I EnableMe",
    //   image: "https://www.enableme.de/global/topics/employee/office.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/arbeiten-mit-depressionen-9572",
    //   title: "Arbeiten mit Depressionen I EnableMe",
    //   image: "https://www.enableme.de/global/topics/employee/work-with-depression.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/burnout-depression-was-sie-wissen-mussen-9568",
    //   title: "Unterschied zwischen Burnout und Depression I EnableMe",
    //   image: "https://www.enableme.de/global/handicaps/depression/sad-man-sitting-in-the-living-room.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/angst-vor-der-arbeit-lassen-sie-sich-nicht-einschranken-9369",
    //   title: "Angst vor der Arbeit I EnableMe",
    //   image: "https://www.enableme.de/countrys/germany/topics/employee/fear_work.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/themen/der-arbeitsmarkt-fur-menschen-mit-behinderung-1208",
    //   title: "Der Arbeitsmarkt für Menschen mit Behinderung",
    //   image: "https://www.enableme.de/countrys/germany/topics/employee/colleagues-during-work.jpg"
    // },
    {
      url: "https://www.enableme.de/de/themen/bewahrung-und-unterstutzung-der-geistigen-gesundheit-1217",
      title: "Psychische Gesundheit und Einflussfaktoren | EnableMe",
      image: "https://www.enableme.de/global/topics/health/friends-eating-together.jpg"
    },
    {
      url: "https://www.enableme.de/de/artikel/der-umgang-mit-negativen-emotionen-2307",
      title: "Der Umgang mit negativen Emotionen",
      image: "https://www.enableme.de/global/topics/relationships/crying-woman.jpg"
    },
    {
      url: "https://www.enableme.de/de/artikel/akzeptanz-und-selbstliebe-durch-geduldige-entschlossenheit-2305",
      title: "Akzeptanz und Selbstliebe durch geduldige Entschlossenheit",
      image: "https://www.enableme.de/global/topics/sexuality/heart.jpg"
    },
    // {
    //   url: "https://www.enableme.de/de/artikel/den-eigenen-zielen-naher-kommen-fokussierter-arbeiten-durch-gesteigerte-konzentrationsfahigkeit-8010",
    //   title: "Gesteigerte Konzentrationsfähigkeit und mehr Fokus",
    //   image: "https://www.enableme.de/countrys/germany/topics/mental-health-advice/green_eye.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/die-optimale-regeneration-tipps-fur-schnelles-einschlafen-und-eine-erholsame-nacht-7868",
    //   title: "Tipps für erholsamen Schlaf",
    //   image: "https://www.enableme.de/countrys/germany/topics/mental-health-advice/sleepy-cat.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/uberwindung-eines-motivationstiefs-2349",
    //   title: "Überwindung eines Motivationstiefs",
    //   image: "https://www.enableme.de/global/topics/sports/climbing.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/berufsunfahig-wegen-einer-behinderung-2619",
    //   title: "Berufsunfähig wegen einer Behinderung",
    //   image: "https://www.enableme.de/countrys/germany/topics/employee/woman-makes-a-calculation.jpg"
    // },
    {
      url: "https://www.enableme.de/de/artikel/prasenzarbeit-oder-home-office-zwei-seiten-einer-medaille-2550",
      title: "Homeoffice für Menschen mit Behinderung",
      image: "https://www.enableme.de/countrys/germany/topics/employee/man-is-working-at-home.jpg"
    },
    {
      url: "https://www.enableme.de/de/artikel/wodurch-integration-scheitern-kann-2475",
      title: "Erfolgreiche Integration I EnableMe",
      image: "https://www.enableme.de/countrys/germany/topics/employee/no-to-a-high-five.jpg"
    },
    // {
    //   url: "https://www.enableme.de/de/artikel/neuanfang-durch-berufsforderungswerke-2104",
    //   title: "Neuanfang durch Berufsförderungswerke",
    //   image: "https://www.enableme.de/countrys/germany/topics/education/thinking-man.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/leistungen-der-agentur-fur-arbeit-fur-arbeitssuchende-mit-behinderung-2002",
    //   title: "Leistungen der Agentur für Arbeit I EnableMe",
    //   image: "https://www.enableme.de/countrys/germany/topics/employee/shaking-hands.jpg"
    // },
    {
      url: "https://www.enableme.de/de/artikel/leistungen-vom-integrationsamt-fur-mitarbeitende-mit-behinderungen-2000",
      title: "Integrationsamt für Arbeitende mit Schwerbehinderung",
      image: "https://www.enableme.de/countrys/germany/topics/employee/integration.jpg"
    },
    // {
    //   url: "https://www.enableme.de/de/artikel/beratung-und-unterstutzung-von-integrationsfachdiensten-1999",
    //   title: "Integrationsfachdienste I EnableMe",
    //   image: "https://www.enableme.de/countrys/germany/topics/employee/image-thumb__1735__header-image/woman-man-office~-~media--60c2f037--query.webp"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/akzeptanz-und-selbstliebe-durch-geduldige-entschlossenheit-23055",
    //   title: "Akzeptanz und Selbstliebe durch geduldige Entschlossenheit",
    //   image: "https://www.enableme.de/global/topics/sexuality/image-thumb__1280__header-image/heart~-~media--60c2f037--query.webp"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/wodurch-integration-scheitern-kann-2475",
    //   title: "Erfolgreiche Integration I EnableMe",
    //   image: "https://www.enableme.de/countrys/germany/topics/employee/no-to-a-high-five.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/arterielle-verschlusskrankheit-achtung-vor-schmerzen-beim-gehen-1197",
    //   title: "Arterielle Verschlusskrankheit – Achtung vor Schmerzen beim Gehen",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/peripheral-artery-disease/red-blood-cells-artery-disease.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/funktionsstorungen-und-erkrankungen-des-darms-2394",
    //   title: "Was ist eine Darmfunktionsstörungen?",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/intestinal-disorders/stomach-heart.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/diabetes-mellitus-eine-storung-des-blutzuckerstoffwechsels-1182",
    //   title: "Diabetes mellitus – eine Störung des Blutzuckerstoffwechsels",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/diabetes/diabetes-hands-glucometer.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/epilepsie-eine-chronische-krankheit-1184",
    //   title: "Epilepsie - Eine chronische Krankheit",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/epilepsy/lightning-epilepsy.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/selbststandig-leben-mit-epileptischen-anfallen-oder-krampfanfallen-6252",
    //   title: "Selbstständig leben mit Krampfanfällen",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/epilepsy/man-sitting-thinking.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/erste-hilfe-bei-einem-epileptischen-anfall-7599",
    //   title: "Erste Hilfe bei einem epileptischen Anfall",
    //   image: "https://www.enableme.de/countrys/germany/topics/employee/someone-is-lying-on-the-couch-with-a-blanket-over-their-head.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/herzinfarkt-ursachen-symptome-und-risikofaktoren-1187",
    //   title: "Herzinfarkt – Ursachen, Symptome und Risikofaktoren",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/heart-attack/stethoscope.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/herzinfarkt-therapie-und-rehabilitation-2129",
    //   title: "Herzinfarkt – Therapie und Rehabilitation",
    //   image: "https://www.enableme.de/global/handicaps/heart-attack/model-of-a-heart.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/herzinfarkt-das-leben-danach-2128",
    //   title: "Herzinfarkt – das Leben danach",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/heart-attack/old-woman-bike.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/hoher-blutdruck-und-seine-folgeschaden-1188",
    //   title: "Hoher Blutdruck (arterielle Hypertonie) und seine Folgeschäden",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/hypertension/heart.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/hoher-blutdruck-hypertonie-schmerzlos-aber-hochgefahrlich-2208",
    //   title: "Hypertonie (Bluthochdruck) – schmerzlos, aber hochgefährlich ",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/hypertension/measuring-blood-pressure-hypertension.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/alltagstipps-zur-bluthochdruck-pravention-7510",
    //   title: "Bluthochdruck vorbeugen: Alltagstipps",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/hypertension/hypertension-prevention.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/inkontinenz-enttabuisierung-einer-volkskrankheit-1189",
    //   title: "Inkontinenz: Was Betroffene tuen können",
    //   image: "https://www.enableme.de/global/handicaps/incontinence/wood-doll-incontinence.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/harninkontinenz-arten-ursachen-und-therapie-2233",
    //   title: "Harninkontinenz – Arten, Ursachen und Therapie",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/incontinence/urine.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/migrane-schmerzen-im-kopf-1931",
    //   title: "Migräne – Schmerzen im Kopf",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/migraine/man-with-turning-head.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/wenn-migrane-einfluss-auf-die-arbeitsleistung-nimmt-antrag-auf-schwerbehindertenausweis-2012",
    //   title: "Schwerbehinderung bei Migräne I EnableMe",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/migraine/people-in-conference-room.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/osteoporose-fruhzeitig-erkennen-und-behandeln-2322",
    //   title: "Osteoporose: frühzeitig erkennen und behandeln",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/osteoporosis/osteoporosis-person-bones.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/osteoporose-vorbeugende-massnahmen-2324",
    //   title: "Osteoporose vorbeugen: Maßnahmen & Tipps",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/osteoporosis/prevention-sport-people.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/fatigue-und-me-cfs-erschopfung-und-mehr-als-erschopfung-9501",
    //   title: "Fatigue und ME/CFS im Vergleich",
    //   image: "https://www.enableme.de/countrys/germany/topics/fatigue/men-sits-on-bed.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/fatigue-korperliche-kognitive-und-seelische-erschopfung-9524",
    //   title: "Fatigue: Symptome, Behandlungen, Tipps",
    //   image: "https://www.enableme.de/countrys/germany/topics/fatigue/woman-lays-on-a-bench.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/das-komplexe-krankheitsbild-von-me-cfs-bedeutet-mehr-als-erschopfung-9525",
    //   title: "ME/CFS: Symptome, Ursachen, Diagnose",
    //   image: "https://www.enableme.de/countrys/germany/topics/fatigue/woman-lays-on-a-couch.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/adhs-als-eine-personliche-und-gesellschaftliche-ressource-9646",
    //   title: "ADHS: Symptome, Stärken, Ursache und Behandlung",
    //   image: "https://www.enableme.de/global/topics/inclusion/community.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/behinderungen/burnout-ursachen-symptome-und-behandlung-1174",
    //   title: "Burnout: vom eigenen Leben überrollt I EnableMe",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/burnout/man-with-burnout.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/wiedereinstieg-nach-burnout-9573",
    //   title: "Wiedereinstieg nach Burnout I EnableMe",
    //   image: "https://www.enableme.de/global/topics/employee/office.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/burnout-gefahren-rechtzeitig-erkennen-2611",
    //   title: "Burnout – Gefahren rechtzeitig erkennen",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/burnout/working-burnout.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/berufsunfahig-durch-burnout-anerkennung-als-krankheit-2609",
    //   title: "Berufsunfähig durch Burnout – Anerkennung als Krankheit",
    //   image: "https://www.enableme.de/countrys/germany/topics/law/financial-aid/justitia.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/burnout-erkennen-und-vermeiden-1246",
    //   title: "Burnout – erkennen und vermeiden I EnableMe",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/burnout/place-to-relax-with-book-and-candle.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/krankschreibung-bei-burnout-mehr-als-nur-eine-auszeit-1245",
    //   title: "Krankschreibung bei Burnout - mehr als nur eine Auszeit ",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/burnout/macbook-on-a-table-between-a-lamp-and-a-plant.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/arbeiten-mit-depressionen-9572",
    //   title: "Arbeiten mit Depressionen I EnableMe",
    //   image: "https://www.enableme.de/global/topics/employee/work-with-depression.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/burnout-depression-was-sie-wissen-mussen-9568",
    //   title: "Unterschied zwischen Burnout und Depression I EnableMe",
    //   image: "https://www.enableme.de/global/handicaps/depression/sad-man-sitting-in-the-living-room.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/apps-zur-hilfe-bei-depressionen-6240",
    //   title: "Apps zur Hilfe bei Depressionen",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/depression/man-smartphone.jpg"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/depression-gehabt-9198",
    //   title: "Das Leben und der Umgang mit Depression",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/depression/breaking-identity.png"
    // },
    // {
    //   url: "https://www.enableme.de/de/artikel/bin-ich-depressiv-der-selbsttest-1265",
    //   title: "Bin ich depressiv? – Der Selbsttest",
    //   image: "https://www.enableme.de/countrys/germany/handicaps/depression/depressed-woman-thinking.jpg"
    // }
];

const slugify = str =>
str
  .toLowerCase()
  .trim()
  .replace(/[^\w\s-]/g, '')
  .replace(/[\s_-]+/g, '-')
  .replace(/^-+|-+$/g, '');

function SubCategories({ref, area, calculation, copyId, isSummary, index, total}) {
    const { t } = useTranslation();
    const [md, setMd] = useState();
    const [language] = useState(i18n.language);

    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    useEffect(() => {
        function fetchCopy(stage = 1, result) {
            fetch(`/copy/${language}/${calculation.copyId}_${calculation.result}.md`)
                .then((r) => r.text())
                .then(md => {
                    setMd(md);
                    setTimeout(()=>  {
                      gsapAnim()
                  }, 1000);                    
                })
        }
        fetchCopy()
    }, []);

    function gsapAnim(){
      sectionRefs.current.forEach(el => {
        ScrollTrigger.create({
          trigger: el,
          markers: false,
          start: "top center",
          end: "bottom center",
          toggleClass: {targets: `#${el.id}-menu`, className: "active"}
        });
      });          
    }

    const addSectionRefs = el => {
      if (el && !sectionRefs.current.includes(el)) {
        sectionRefs.current.push(el);
      }
    };


    return (
        <>
            <div ref={addSectionRefs} id={slugify(area)+"_"+slugify(calculation.focus)} className={isSummary ? "ResultsPage--Content--Category--Subcategory isSummary" : "ResultsPage--Content--Category--Subcategory"}>

                <div className="ResultsPage--Content--Category--Subcategory--Header">
                    <div className="ResultsPage--Content--Category--Subcategory--Header--name">
                        <span>
                            <Icon name={icons[slugify(area)]} />
						    {t("results", { returnObjects: true }).find((obj) => obj.copyId === calculation.copyId).label}
                        </span>
                    </div>
                    <div className="ResultsPage--Content--Category--Subcategory--Header--focus">
                        <span>
                            {t("results", { returnObjects: true }).find((obj) => obj.copyId === copyId).label}
                        </span>
                    </div>
                    {isSummary &&
                    <div className="ResultsPage--Content--Category--Subcategory--Header--score"><input readOnly type="range"
                        min={calculation.minPossible} max={calculation.maxPossible} value={calculation.avg} step="0.1"/></div>
                    }
                    {!isSummary && <span className="ResultsPage--Content--Category--Subcategory--Header--index">{index +"/"+ total}</span>}
                </div>
                <div className={"ResultsPage--Content--Category--Subcategory--Content " + "result-" + calculation.result}>
                    <ReactMarkdown>{md}</ReactMarkdown>
                </div>
            </div>
        </>
    );
}

function Categories({area}) {
    const [introMd, setIntroMd] = useState();
    const [language] = useState(i18n.language);
    
    const sectionRefs = useRef([]);
    sectionRefs.current = [];

    useEffect(() => {
        function fetchCopy(stage = 1, result) {
            fetch(`/copy/${language}/${area.copyId}_intro.md`)
                .then(md => {
                    setIntroMd(md);
                    setTimeout(()=>  {
                      gsapAnim()
                  }, 1000);                    
                })
        }

        function gsapAnim(){
          sectionRefs.current.forEach(el => {
  
            ScrollTrigger.create({
              trigger: el,
              markers: false,
              start: "top top",
              end: "bottom top",
              toggleClass: {targets: `#${el.id}-menu`, className: "active"}
            });
          });          
        }

        fetchCopy()
    }, []);

    const addSectionRefs = el => {
      if (el && !sectionRefs.current.includes(el)) {
        sectionRefs.current.push(el);
      }
    };

    return (
        <section ref={addSectionRefs} className="ResultsPage--Content--Category" key={area.area} id={slugify(area.area)}>
            {area.calculations.map((c, i) => <SubCategories isSummary={i === 0} index={i} area={area.area} copyId={area.copyId} calculation={c} key={c.focus} total={area.calculations.length - 1}/> )}
        </section>
    )
}


function IntroSummary({area, calculation, copyId}) {
    const [md, setMd] = useState();
    const { t } = useTranslation();
    const [language] = useState(i18n.language);   

    useEffect(() => {
        function fetchCopy(stage = 1, result) {
            fetch(`/copy/${language}/${calculation.copyId}_${calculation.result}.md`)
                .then((r) => r.text())
                .then(md => {
                    setMd(md);
                })
        }

        fetchCopy()
    }, []);

    return (
        <>
            <div className={"ResultsPage--Summary--Category" + " result-"+calculation.result}>
                <div className="ResultsPage--Summary--Category--Area">
                    <Icon name={icons[slugify(area)]} />
                    <strong>{t("results", { returnObjects: true }).find((obj) => obj.copyId === copyId).label}</strong>
                </div>
                <div className="ResultsPage--Summary--Category--Content">
                    <ReactMarkdown>{md}</ReactMarkdown>
                </div>
                <div className="ResultsPage--Summary--Category--Score">
                  <input readOnly type="range" min={calculation.minPossible} max={calculation.maxPossible} value={calculation.avg} step="0.1"/>  
                </div>
            </div>
        </>
    );
}

export default function Results({results, average}) {
    const { t } = useTranslation();
    const [language] = useState(i18n.language);    
    const chartLabels = results.map(({area}) => area);
    const dataValues = results.map(({calculations}) => calculations[0].avg);

    const reference = useRef();
    setTimeout(()=>  {
      ScrollTrigger.create({
        trigger: reference.current,
        markers: false,
        start: "top top",
        end: "bottom center",
        toggleClass: "active"
      });
    },1000);

    function printPage(e) {
        window.print();
    }

    function getRandom(arr, n) {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    return (
        <div className="ResultsPage">
            <div className="ResultsPage--Intro">
                {/* {window.location.href.includes('debug') ? ( */}
                    <pre>
                        <label>hide</label>
                        <input name='hide' type="checkbox" />
                    <code>
                    {
                        JSON.stringify({average, results}, undefined, 2)
                    }
                    </code>
                    </pre>
                {/* ) : ( */}
                    {/* console.log('') */}
                {/* )} */}

                <h1>{t("pages:results.intro.title")}</h1>
                <p>{t("pages:results.intro.text")}</p>

            </div>

            <div className="ResultsPage--Summary">
            {
                results.map((area, i) => (
                    area.calculations.map((c,k) => k == 0 ? <IntroSummary area={area.area} copyId={area.copyId} calculation={c} key={c.focus} /> : null)
                ))
            }
            </div>

            {<div className="ResultsPage--Chart--Desc">
                <span id='ResultsPage--Chart--Desc--nicht-offenlegen'>{t("dictionary.noDisclose")}</span>
                <span id='ResultsPage--Chart--Desc--offenlegen'>{t("dictionary.disclose")}</span>
            </div>}            

            <div ref={reference} className="ResultsPage--Content">
              <div className="ResultsPage--Nav">
                  <input id='overview' type="checkbox" />
                  <label for="overview"><span>{t("dictionary.overview")}</span> <Icon name='plus-nocircle' /><Icon name='minus-nocircle' /></label>
                  <ul className="ResultsPage--Nav--List">
                      {
                          results.map((area, i) => (
                              <li id={slugify(area.area)+"-menu"}>

                                <a href={"#"+slugify(area.area)+"_ingesamt"}><Icon name='arrow-right' /><span>{t("results", { returnObjects: true }).find((obj) => obj.copyId === area.copyId).label}</span></a>
                                <ul className="ResultsPage--Nav--List--Sublist">
                                  {area.calculations.map((c,k) => k != 0 ? <li id={slugify(area.area)+"_"+slugify(c.focus)+"-menu"}><a href={"#"+slugify(area.area)+"_"+slugify(c.focus)}><Icon name='arrow-right' /><span>{t("results", { returnObjects: true }).find((obj) => obj.copyId === c.copyId).label}</span></a></li> : "")}
                                </ul>
                              </li>
                          ))
                      }
                  </ul>              
              </div>                
            {
                results.map((area, i) => (
                    <Categories key={area.area} area={area} />
                ))
            }
            </div>

            <div className="ResultsPage--Download">
                <h2>{t("pages:results.download.title")}</h2>
                <button onClick={printPage}>{t("dictionary.downloadPdf")}</button>
                <p>
                    <small>{t("pages:results.download.text")}</small>
                </p>
            </div>

            <div className="ResultsPage--Links">
                <div className="ResultsPage--Links--inner">
                    <div className="ResultsPage--Links--brand">
                        <span>Powered by</span>
                        <img alt='EnableMe - Together we grow' src={logoEnableMe} />
                    </div>
                    <h2>{t("pages:results.links.title")}</h2>
                    <ul>
                        {
                            getRandom(externalLinks,9).map((link, i) => (
                                <li>
                                    <img src={link.image} alt={link.title} />
                                    <h4>{link.title}</h4>
                                    <a href={link.url+'?mtm_campaign=safespace-survey'} target='_blank'>
                                        <span>Mehr erfahren</span>
                                        <Icon name='external-link' />
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    );
}
